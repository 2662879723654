.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar{
  display: none;
}
.slick-slide >div{
  margin:0 20px;
}
.slick-list{
  margin:0 -20px;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
.floating{
  animation:floating 7s ease infinite ;
}
.PhoneInputInput{
  outline: none;
  border: none;
  background: transparent;
}
@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
.drop-nav-shadow{
  box-shadow: 0px 4px 21.9px 0px rgba(0, 0, 0, 0.25);
}
.bg-image-animate{
  background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) ,url('/public/img/bgAnimation.gif') center center /cover no-repeat;
}